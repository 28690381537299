let base64js = require("@/util/base64");
import { api_request } from "@/util/network";

function bufferDecode(value) {
  return Uint8Array.from(atob(value), c => c.charCodeAt(0));
}

// Encode an ArrayBuffer into a base64 string.
function bufferEncode(value) {
  return base64js
    .fromByteArray(value)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}

// 当前设备是否支持fido
function supportFido() {
  if (!window.PublicKeyCredential) return;
  return true;
}

// 当前设备是否支持fido
function isWindows() {
  const userAgent = navigator.userAgent.includes("Windows");
  return userAgent;
}

function saveLastAccount(data) {
  const {
    claims: { nickname, login_name, avatar_url },
    name
  } = data.identity;
  localStorage.setItem(
    "last-account",
    JSON.stringify({
      name,
      login_name,
      nickname,
      avatar_url
    })
  );
}

function isHasKeys(_this, id) {
  return new Promise(resolve => {
    let accountInfo = JSON.parse(localStorage.getItem("last-account"));
    return _this.$http
      .get(`api/source/${id}/start?account_id=${accountInfo.name}`)
      .delegateTo(api_request)
      .then(({ content: { credential_ids } }) => {
        resolve(credential_ids);
      })
      .catch(({ code }) => {
        throw `获取keys数据失败：${_this.$t("api." + code)}`;
      })
      .delegateTo(_this.$snackbar.delegateError);
  });
}

export {
  bufferDecode,
  bufferEncode,
  supportFido,
  isWindows,
  saveLastAccount,
  isHasKeys
};
